.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.canvas-bg{
  position: fixed;
  background: linear-gradient(45deg, rgba(198,116,232,1) 45%, rgba(0,212,255,1) 100%);
  left: 0;
  top:0;
  bottom:0;
  right: 0;
  z-index: -2;
}

.App-header {
  /* background: rgb(198,116,232); */
  padding: 10px;
  background: rgba(0,0,0,0.1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-wrapper{
  max-width: 500px;
  width: 100%;
  /* border: 5px solid white; */
  box-sizing: border-box;
}

a{
  /* border-radius: 5px; */
  background: rgba(113, 21, 126, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.945);
  padding: 10px;
  text-decoration: none;
  font-weight: bolder;
  display: block;
  margin-bottom: 20px;
  counter-reset: #61dafb;
}

a,a:visited{
  color: white;
}

a:hover{
  background: rgba(243, 15, 175, 0.6);
}

.playlist-wrapper{
  margin: 30px 0;
}

#canvas{
  position: fixed;
  z-index: -1;
}